<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' Settings'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <!-- <a-form-model-item
              style="margin: unset;"
              ref="id_bpr"
              label="BPR"
              prop="id_bpr"
            >
              <a-select v-model="form.id_bpr" show-search option-filter-prop="children" :filter-option="filterOption">
                <a-select-option
                  v-for="data in databpr"
                  :key="data.sandibpr"
                  :value="data.sandibpr"
                  >{{ data.sandibpr + ' - ' + data.namabpr }}</a-select-option
                >
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item
              style="margin: unset;"
              ref="kode"
              label="Kode"
              prop="kode"
            >
              <a-input
                v-model="form.kode"
                @blur="
                  () => {
                    $refs.kode.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="keterangan"
              label="Keterangan"
              prop="keterangan"
            >
              <a-input
                v-model="form.keterangan"
                @blur="
                  () => {
                    $refs.keterangan.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  name: 'editprofile',
  created() {
    // this.getMaster()
  },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datarole: [],
      databpr: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        kode: '',
        keterangan: '',
      },
      rules: {
        kode: [{ required: true, message: 'Please input Kode!' }],
        keterangan: [{ required: true, message: 'Please input Keterangan!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    moment,
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    selectDate(selectedDates, dateString, input) {
      // console.log('selectedDates', selectedDates)
      // console.log('dateString', dateString)
      // console.log('input', input)
      if (selectedDates !== null) {
        this.form[input] = dateString
      } else {
        this.form[input] = null
      }
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.appLists = temp
    },
    async showModal(action, data = {}) {
      // console.log('data', data)
      this.editdata = data
      // var resbpr = await lou.customUrlGet('auth/sandibpr', false, true)
      // this.databpr = resbpr.data
      if (action === 'Update') {
        this.form.id = data.id
        this.form.kode = data.kode
        this.form.keterangan = data.keterangan
        // console.log('this.form.selectedAppLists', this.form.selectedAppLists)
      } else {
        this.form.kode = ''
        this.form.keterangan = ''
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log('this.form.appLists', this.form.appLists)
          var fd = {
            kode: this.form.kode,
            keterangan: this.form.keterangan,
          }
          if (this.action === 'Create') {
            await lou.customUrlPost('jaminan/settings/many', [fd], true, true)
          } else {
            fd.id = this.form.id
            await lou.customUrlPut('jaminan/settings/many', [fd], true, true)
          }
          this.$parent.getData()
          this.visible = false
          this.resetForm(formName)
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
